import { useRef } from 'react';
import { PlaybackEvent } from '@24i/nxg-sdk-photon/src/analytics/player';
import {
    CommonPlayerEventParams,
    CommonPlayerEventTriggerParams,
    useCommonPlayerEventParams,
} from '../useCommonPlayerEventParams';
import { useTriggerOnce } from '../../hooks/useTriggerOnce';
import { useTriggerOnPlayerEvent } from '../useTriggerOnPlayerEvent';

export const useTriggerOnPlaybackStart = (params: CommonPlayerEventParams) => {
    const triggerPlayerEvent = useTriggerOnPlayerEvent(params, 'PLAYBACK_START');
    const event = useCommonPlayerEventParams(params);

    const eventRef = useRef<Omit<PlaybackEvent, 'type'> | undefined>();
    if (event) {
        eventRef.current = event;
    }

    const { triggerOnce } = useTriggerOnce<Omit<PlaybackEvent, 'type'>>(
        (e) => `${e.asset?.id}`,
        ({ percentOfPlayback, currentPlaybackTimeMs, duration }) =>
            triggerPlayerEvent({ percentOfPlayback, currentPlaybackTimeMs, duration })
    );

    const trigger: (params: CommonPlayerEventTriggerParams) => void = ({
        percentOfPlayback,
        currentPlaybackTimeMs,
        duration,
    }) => {
        const finalEvent = eventRef.current || event;
        if (!finalEvent) {
            return;
        }
        const eventWithCurrentTime = {
            ...finalEvent,
            percentOfPlayback,
            currentPlaybackTimeMs,
            duration,
        };
        triggerOnce({ ...eventWithCurrentTime });
    };

    return trigger;
};
